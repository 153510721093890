import { useEffect, useState } from "react";

export default function Videos() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const vids = importAll(
      require.context("../../images/videos", false, /\.mp4$/)
    );
    setVideos(vids);
  }, []);

  return (
    <div className="bg-cover bg-fixed bg-[url('../src/images/galerie/IMG_3453.jpg')]">
      <div className="flex flex-col sm:flex-row gap-4 justify-around p-3 bg-opacity-75 bg-black items-center">
        {videos.map((v) => (
          <video
            className="w-[300px] md:w-[300px] lg:w-[400px]"
            controlsList="nodownload"
            controls
          >
            <source src={v} type="video/mp4" />
          </video>
        ))}
      </div>
    </div>
  );
}
