import {
  Sidenav,
  DashboardNavbar,
  Configurator,
  Footer,
} from "../widgets/layout";
import routes from "../routes";
import {
  useMaterialTailwindController,
  setOpenConfigurator,
} from "../context/index";
import { useAuth } from "../../../AuthProvider";

export function Dashboard({ children }) {
  const [controller, dispatch] = useMaterialTailwindController();
  const { sidenavType } = controller;
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-white">
      <Sidenav
        routes={routes(user)}
        brandImg={
          sidenavType === "dark" ? "/img/logo-ct.png" : "/img/logo-ct-dark.png"
        }
      />
      <div className="p-4 xl:ml-[18rem]">
        <DashboardNavbar />
        {children}
        <div className="text-black">
          <Footer />
        </div>
      </div>
    </div>
  );
}

Dashboard.displayName = "/src/layout/dashboard.jsx";

export default Dashboard;
