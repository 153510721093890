import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/residence.png";
import { useAuth } from "./AuthProvider";
import ProfilePopper from "./ProfilePopper";
import CustomAlert from "./Utility/CustomAlert";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Header(props) {
  const { alert, loggedIn, clearAlert } = useAuth();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const handleMobileMenuToggle = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="pb-6 bg-[#B11217] lg:pb-0">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-2">
      <nav className="flex items-center justify-between h-16 lg:h-20">
    <div className="flex-shrink-0">
        <a aria-current="page" className="active" href="/">
            <img className="w-auto h-12 md:h-16 lg:h-20" src={logo} alt="" draggable="false" />
        </a>
    </div>
    <div className="ml-auto flex items-center">
        <a className="lg:hidden ml-2 inline-block" href="#footer">
          <InfoOutlinedIcon titleAccess="Info et contacts" draggable="false" className="inline text-base font-medium text-white transition-all duration-200 hover:text-black focus:text-black lg:hidden" />
        </a>
        <button type="button" className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100" onClick={handleMobileMenuToggle}>
            <svg className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16"></path>
            </svg>
            <svg className="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
        <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            <a aria-current="page" className="active" href="/">
                <span href="#" draggable="false" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-black focus:text-black"> Accueil </span>
            </a>
            <a className="" href="/residence">
                <span href="#" draggable="false" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-black focus:text-black"> Résidence </span>
            </a>
            <a className="" href="/gallerie">
                <span href="#" draggable="false" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-black focus:text-black"> Galerie </span>
            </a>
            <a className="" href="/annonce">
                <span href="#" draggable="false" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-black focus:text-black"> Annonces </span>
            </a>
            <a href="#footer">
                <InfoOutlinedIcon draggable="false" titleAccess="Info et contacts" className="text-base font-medium text-white transition-all duration-200 hover:text-black focus:text-black"/>
            </a>
        </div>
        {!loggedIn ? ( 
          <a className="" href="/connexion">
            <span href="#" draggable="false" title="" className="items-center justify-center hidden px-4 py-3 ml-10 text-base font-semibold text-white transition-all duration-200 bg-black border border-transparent rounded-md lg:inline-flex hover:bg-gray-900 focus:bg-gray-900" role="button"> Connexion </span>
          </a>) 
        : (<ProfilePopper
              shouldHandleClick={true}
              classes="p-1 hover:shadow-red-700 hover:shadow-inner ml-10 rounded-full"
            />
          )}
       
    </div>
</nav>


        <nav className={`${isMobileMenuOpen ? 'block' : 'hidden'} pt-4 pb-6 bg-white border border-gray-200 rounded-md shadow-md lg:hidden`}>
          <div className="flow-root">
            <div className="flex flex-col px-6 -my-2 space-y-1">
              <NavLink
                to="/"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-black focus:text-black"
              >
                {" "}
                Accueil{" "}
              </NavLink>

              <NavLink
                to="/residence"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-black focus:text-black"
              >
                {" "}
                Résidence{" "}
              </NavLink>

              <NavLink
                to="/gallerie"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-black focus:text-black"
              >
                {" "}
                Galerie{" "}
              </NavLink>

              <NavLink
                to="/annonce"
                className="inline-flex py-2 text-base font-medium text-black transition-all duration-200 hover:text-black focus:text-black"
              >
                {" "}
                Annonces{" "}
              </NavLink>
            </div>
          </div>

          {!loggedIn ? (
            <div className="px-6 mt-6">
              <a
                href="/connexion"
                title=""
                className="inline-flex justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 bg-black border border-transparent rounded-md tems-center hover:bg-black focus:bg-black"
                role="button"
              >
                {" "}
                Connexion
              </a>
            </div>
          ) : (null)}
        </nav>
        {alert && <CustomAlert alertSeverity={loggedIn ? "error" : "success"} alert={alert} closeAlert={() => clearAlert()} />}
      </div>
    </header>
  );
}
