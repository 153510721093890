export default function Activity(props) {
	return (
		<svg fill={props.fill} width={props.width} height={props.height} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 64.022 64.022">
			<g>
				<g>
					<rect x="13.52" y="44.563" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -30.7577 24.769)" width="2" height="9.899" />
				</g>
			</g>
			<g>
				<g>
					<path d="M21.02,37.011c-3.309,0-6,2.691-6,6c0,3.309,2.691,6,6,6c3.309,0,6-2.691,6-6C27.02,39.702,24.329,37.011,21.02,37.011z
			 M21.02,47.011c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S23.226,47.011,21.02,47.011z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M63.729,8.304l-8-8c-0.391-0.391-1.023-0.391-1.414,0l-2.293,2.293l-2.293-2.293l-1.414,1.414l2.291,2.293L47.02,7.597
			l-2.293-2.293l-1.414,1.414l2.293,2.293l-2.293,2.293c-0.391,0.391-0.391,1.023,0,1.414l1.293,1.293L33.772,24.846
			c-1.445-0.721-3.029-1.159-4.669-1.25c-0.036-0.002-0.072,0.001-0.108,0L8.938,1.676C7.977,0.629,6.666,0.038,5.246,0.012
			c-1.42-0.033-2.751,0.517-3.748,1.529c-1.973,2.001-1.961,5.247,0.026,7.233l18.371,18.371c-0.641,0.649-1.209,1.364-1.681,2.133
			c-1.664,2.703-4.018,4.524-6.626,5.128c-3.174,0.734-5.972,2.452-8.092,4.97c-4.798,5.697-4.64,14.162,0.367,19.69
			c2.793,3.083,6.613,4.837,10.759,4.94c0.129,0.004,0.257,0.005,0.385,0.005c4.003,0,7.753-1.552,10.595-4.394
			c1.964-1.963,3.34-4.423,3.977-7.114c0.656-2.764,2.415-5.141,4.824-6.52c0.901-0.515,1.712-1.153,2.449-1.881l12.862,12.862
			c-0.768,2.053-0.52,4.013,0.699,5.231c0.758,0.758,1.787,1.152,2.971,1.152c0.378,0,0.771-0.04,1.175-0.121
			c1.527-0.305,3.068-1.173,4.339-2.445c2.775-2.775,3.397-6.502,1.415-8.485c-1.185-1.184-3.091-1.451-5.094-0.749L40.427,36.001
			c0.066-1.968-0.352-3.945-1.241-5.741l10.835-10.835l1.293,1.293c0.093,0.093,0.204,0.168,0.326,0.218
			c0.113,0.046,0.206,0.085,0.315,0.085c0.373,0,0.934-0.461,3.079-2.583l2.281,2.281l1.414-1.414l-2.278-2.278
			c0.531-0.532,1.123-1.125,1.798-1.802c0.605-0.607,1.208-1.212,1.783-1.79l2.283,2.283l1.414-1.414l-2.287-2.287
			c1.338-1.345,2.289-2.301,2.289-2.301C64.12,9.325,64.119,8.693,63.729,8.304z M2.938,7.361c-1.213-1.213-1.22-3.194-0.017-4.416
			c0.608-0.618,1.407-0.947,2.288-0.934c0.867,0.016,1.668,0.377,2.254,1.015l18.962,20.723c-1.803,0.306-3.509,1.029-4.998,2.101
			L2.938,7.361z M36.671,41.25c-0.877,1.252-1.974,2.261-3.262,2.999c-2.887,1.653-4.992,4.494-5.775,7.793
			c-0.553,2.33-1.745,4.46-3.446,6.162c-2.541,2.541-5.912,3.894-9.516,3.804c-3.592-0.089-6.904-1.61-9.325-4.283
			c-4.339-4.79-4.477-12.124-0.32-17.06c1.839-2.184,4.265-3.674,7.014-4.309c3.134-0.725,5.932-2.866,7.878-6.028
			c0.404-0.657,0.889-1.269,1.441-1.822c1.878-1.879,4.431-2.929,7.075-2.929c0.186,0,0.371,0.005,0.558,0.016
			c2.88,0.161,5.467,1.504,7.284,3.781c1.67,2.092,2.374,4.729,2.104,7.279l-0.05,0.047l0.042,0.044
			C38.188,38.334,37.625,39.888,36.671,41.25z M40.066,38.524l14.172,14.894c0.295,0.309,0.754,0.398,1.14,0.22
			c1.46-0.667,2.807-0.64,3.521,0.072c1.149,1.15,0.501,3.741-1.416,5.657c-0.995,0.995-2.173,1.669-3.317,1.898
			c-0.575,0.115-1.648,0.206-2.339-0.484c-0.732-0.732-0.742-2.118-0.027-3.617c0.183-0.382,0.104-0.838-0.195-1.138L38.162,42.585
			c0.047-0.065,0.101-0.122,0.147-0.188C39.144,41.205,39.723,39.889,40.066,38.524z M38.115,28.503
			c-0.092-0.126-0.179-0.255-0.276-0.377c-0.675-0.846-1.461-1.575-2.316-2.202L46.02,15.426l2.586,2.585L38.115,28.503z
			 M52.032,18.61l-1.305-1.305l-4-4l-1.293-1.294l9.586-9.585l6.588,6.588C58.187,12.455,53.71,16.948,52.032,18.61z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M30.02,0.011c-4.411,0-8,3.589-8,8c0,4.412,3.589,8,8,8s8-3.589,8-8S34.431,0.011,30.02,0.011z M30.02,14.011
			c-0.898,0-1.747-0.204-2.513-0.559c0.988-1.859,1.513-3.924,1.513-6.047V7.011c0-0.551,0.448-1,1-1s1,0.449,1,1v0.394
			c0,2.123,0.525,4.188,1.513,6.047C31.767,13.808,30.918,14.011,30.02,14.011z M34.193,12.313c-0.766-1.518-1.173-3.19-1.173-4.907
			V7.011c0-1.654-1.346-3-3-3s-3,1.346-3,3v0.394c0,1.718-0.407,3.389-1.173,4.907c-1.125-1.09-1.827-2.614-1.827-4.301
			c0-3.308,2.692-6,6-6c3.309,0,6,2.691,6,6C36.02,9.698,35.318,11.222,34.193,12.313z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<rect x="48.606" y="10.011" transform="matrix(0.7071 -0.7071 0.7071 0.7071 6.8643 38.5946)" width="2.828" height="2" />
						<rect x="53.019" y="5.597" transform="matrix(0.7071 0.7071 -0.7071 0.7071 20.7795 -36.1437)" width="2" height="2.828" />
						<rect x="55.606" y="9.012" transform="matrix(0.7071 -0.7071 0.7071 0.7071 9.621 43.2518)" width="2.828" height="2" />
						<rect x="52.02" y="12.597" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 80.6033 61.4096)" width="2" height="2.828" />
					</g>
				</g>
			</g>
			<g>
				<g>
					<rect x="62.02" y="26.011" width="2" height="2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="58.02" y="26.011" width="2" height="2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="54.02" y="26.011" width="2" height="2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="58.02" y="30.011" width="2" height="2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="54.02" y="30.011" width="2" height="2" />
				</g>
			</g>
			<g>
				<g>
					<rect x="50.02" y="30.011" width="2" height="2" />
				</g>
			</g>
		</svg>
	);
}