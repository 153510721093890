import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuth } from "../AuthProvider";
import logo from "../../images/login.jpg";
import CustomAlert from "../Utility/CustomAlert";
import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import ReCAPTCHA from "react-google-recaptcha";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" href="http://localhost:3000">
				Résidence à la Croisée du Bonheur
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

// TODO remove, this demo shouldn't need to reset the theme.

let defaultTheme = createTheme();
defaultTheme = createTheme(defaultTheme, {
	// Custom colors created with augmentColor go here
	palette: {
		customRed: defaultTheme.palette.augmentColor({
			color: {
				main: "#B11217",
			},
			name: "customRed",
		}),
	},
});

export default function SignInSide() {
	const { loggedIn, login, alert, clearAlert, recaptchaRequested } = useAuth();
	const [loading, setLoading] = useState(false);
	const [failedAttempt, setFailedAttempt] = useState(0);
	const recaptcha = React.useRef();

	const keySite = process.env.REACT_APP_SITE_KEY;

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const email = formData.get("email");
		const password = formData.get("password");
		setLoading(true);
		await login(email, password, recaptcha, (recaptchaRequested ? 3 : failedAttempt), () => {
			setFailedAttempt((failedAttempt) => failedAttempt + 1);
		});
		setLoading(false);
	};

	useEffect(() => {
	},[recaptchaRequested])

	return (
		<>
			{loggedIn ? null : (
				<ThemeProvider theme={defaultTheme}>
					<Grid container component="main" sx={{ height: "100vh" }}>
						<CssBaseline />
						<Grid
							item
							xs={false}
							sm={4}
							md={7}
							sx={{
								backgroundImage: `url(${logo})`,
								backgroundRepeat: "no-repeat",
								backgroundColor: (t) =>
									t.palette.mode === "light"
										? t.palette.grey[50]
										: t.palette.grey[900],
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						/>

						<Grid
							item
							xs={12}
							sm={8}
							md={5}
							component={Paper}
							elevation={6}
							square
						>
							{alert !== "" ? (
								<div className="m-5">
									<CustomAlert
										closeAlert={clearAlert}
										alert={alert}
										alertSeverity={"error"}
									/>
								</div>
							) : null}
							<Link href="/">
								<ArrowLeftIcon className="absolute cursor-pointer mt-4 ml-4 bg-[#B11217] p-1 outline outline-1 hover:outline-offset-4 hover:outline-red-200 text-white rounded-full size-10" />
							</Link>
							<Box
								sx={{
									my: 8,
									mx: 4,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Avatar sx={{ m: 1, bgcolor: "#B11217" }}>
									<LockOutlinedIcon />
								</Avatar>
								<Typography component="h1" variant="h5">
									Connexion
								</Typography>
								<Box
									component="form"
									noValidate
									onSubmit={handleSubmit}
									sx={{ mt: 1 }}
								>
									<TextField
										margin="normal"
										required
										fullWidth
										id="email"
										label="Adresse courriel"
										name="email"
										autoComplete="email"
										autoFocus
									/>
									<TextField
										margin="normal"
										required
										fullWidth
										name="password"
										label="Mot de passe"
										type="password"
										id="password"
										autoComplete="current-password"
									/>
									{failedAttempt >= 3 || recaptchaRequested ? (
										<ReCAPTCHA
											style={{ display: "inline-block", margin: "auto" }}
											ref={recaptcha}
											sitekey={keySite}
										/>
									) : (
										""
									)}
									<Button
										type="submit"
										fullWidth
										variant="contained"
										sx={{
											mt: 3,
											mb: 2,
											backgroundColor: "#B11217",
											"&:hover": {
												backgroundColor: "#B11217",
											},
										}}
									>
										{loading ? (
											<CircularProgress color="inherit" size="1.75rem" />
										) : (
											"Sign In"
										)}
									</Button>
									<Grid container>
										<Grid item xs>
											<Link href="#" variant="body2">
												Forgot password?
											</Link>
										</Grid>
									</Grid>
									<Copyright sx={{ mt: 5 }} />
								</Box>
							</Box>
						</Grid>
					</Grid>
				</ThemeProvider>
			)}
		</>
	);
}
