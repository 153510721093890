import {
  HomeIcon,
  UserCircleIcon,
  TableCellsIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { Home } from "./pages/dashboard/index";
import Residents from "./pages/dashboard/Residents";
import Employes from "./pages/dashboard/Employes";
import Familles from "./pages/dashboard/Familles";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { Work, Badge, People, Diversity1Rounded } from '@mui/icons-material';
import ProfileResident from "./pages/dashboard/ProfileResident";
import Postes from "./pages/dashboard/Postes";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const icon = {
  className: "w-5 h-5 text-inherit",
};

const buildRoutes = (user) => {
  if (user) {
    let array = [
      {
        icon: <HomeIcon {...icon} />,
        name: "Accueil",
        path: "/home",
        element: <Home />,
      },
    ];

    if (user.type === "Famille") {
      array.push({
        icon: <People {...icon} />,
        name: "Résident",
        path: "/resident",
        element: <ProfileResident />,
      });
    } else if (user.role !== "aucun") {
      array.push(
        {
          icon: <People {...icon} />,
          name: "Résidents",
          path: "/residents",
          element: <Residents />,
        },
        {
          icon: <Diversity1Rounded {...icon} />,
          name: "Familles",
          path: "/familles",
          element: <Familles />,
        }
      );
      if (user.role === "Administrateur") {
        array.push({
          icon: <Badge {...icon} />,
          name: "Employés",
          path: "/employes",
          element: <Employes />,
        });
        array.push({
          icon: <Work {...icon} />,
          name: "Postes",
          path: "/postes",
          element: <Postes />,
        })
      }
    }
    return array;
  }
  return [{}];
};

export const routes = (user) => {
  return [
    {
      layout: "dashboard",
      pages: buildRoutes(user),
    },
  ];
};

export default routes;
