import { Typography } from "@material-tailwind/react";
import logo from "../images/residence.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function footer() {
  return (
    <footer id="footer" className="w-full bg-[#B11217] p-6">
      <div className="flex flex-wrap justify-center md:justify-between">
        <div id="location" className="flex flex-col text-center md:text-start">
          <Typography color="white" className="font-normal">
            12 Boulevard Mercier, Beaumont
          </Typography>
          <Typography color="white" className="font-normal">
            QC, G0R 1C0
          </Typography>
        </div>
        <div id="contact" className="flex flex-col text-center md:text-end">
          <Typography color="white" className="font-normal">
            Téléphone : <a href="tel:4188382504">{"(418) 838-2504"}</a>
          </Typography>
          <Typography color="white" className="font-normal">
            Courriel :{" "}
            <a href="mailto:direction@residencecroiseebonheur.com">
              direction@residencecroiseebonheur.com
            </a>
          </Typography>
        </div>
      </div>
      <div className="flex justify-between items-center gap-1">
        <hr className="my-4 border-black inline grow" />
        <InfoOutlinedIcon fontSize="large" className="flex" />
        <hr className="my-4 border-black inline grow" />
      </div>
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-[#B11217] text-center md:justify-between">
        <img src={logo} alt="logo-ct" className="w-48" draggable="false"></img>
        <div className="flex flex-col text-center md:text-end">
          <Typography color="white" className="font-normal">
            &copy; {new Date().getFullYear()} Résidence à la Croisée du Bonheur
          </Typography>
          <Typography color="white" className="font-normal">
            Programmé par Maxime Légaré et Olivier Robitaille
          </Typography>
        </div>
      </div>
    </footer>
  );
}
