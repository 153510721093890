import { useParams } from "react-router-dom";
import Header from "../header";
import Footer from "./../footer";
import ListeChambre from "./ListeChambre";
import Visite from "./Visite";
import TypeChambre from "./TypeChambre";

export default function Residence() {
  const params = useParams();

  return (
    <>
      <Header></Header>
      <div className="bg-gradient-to-b from-[#B11217] w-full flex flex-col min-h-max justify-center items-center">
      {
        params.type ? <TypeChambre type={params.type} /> : <ListeChambre/>
      }
      </div>
      <Visite />
      <Footer></Footer>
    </>
  );
}
