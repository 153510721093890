import { useEffect } from "react";

export default function Visite() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://calendar.google.com/calendar/scheduling-button-script.js";
    script.async = true;

    script.onload = () => {
      window.calendar.schedulingButton.load({
        url: "https://calendar.google.com/calendar/appointments/schedules/AcZssZ0mKuMo7YQ5WCIO75qKSX8Uj9R4w3KXW_PHF71eGsqtZs5HzlseX6cthGzWpp9-VKEAy9wIX36U?gv=true",
        color: "#b11217",
        label: "Planifier une visite  🡪",
        target: script,
      });
    };

    document.getElementById("btn-google1").appendChild(script);

    return () => {
      if (document.getElementById("btn-google1"))
        document.getElementById("btn-google1").removeChild(script);
    };
  }, []);

  return (
    <div className="grid max-w-screen-xl mt-16 px-4 py-8 mx-auto lg:gap-8 xl:gap-0 justify-center mb-28">
      <div className="mr-auto place-self-center lg:col-span-7">
        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-4xl text-black">
          Besoin de visiter une chambre en personne?
        </h1>
        <p className="max-w-2xl mb-6 font-medium lg:mb-8 md:text-lg lg:text-xl text-black">
          Prenez un rendez-vous afin que nous puissions organiser votre visite!
        </p>
        <link
          href="https://calendar.google.com/calendar/scheduling-button-script.css"
          rel="stylesheet"
        />
        <script
          src="https://calendar.google.com/calendar/scheduling-button-script.js"
          async
        ></script>
        <div id="btn-google1"></div>
      </div>
    </div>
  );
}
