import React, { useEffect } from "react";
import heroImage from "../../images/old.png";

const Hero = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://calendar.google.com/calendar/scheduling-button-script.js";
    script.async = true;

    script.onload = () => {
      window.calendar.schedulingButton.load({
        url: "https://calendar.google.com/calendar/appointments/schedules/AcZssZ0mKuMo7YQ5WCIO75qKSX8Uj9R4w3KXW_PHF71eGsqtZs5HzlseX6cthGzWpp9-VKEAy9wIX36U?gv=true",
        color: "#b11217",
        label: "Planifier une visite  🡪",
        target: script,
      });
    };

    document.getElementById("btn-google").appendChild(script);

    return () => {
      if (document.getElementById("btn-google"))
        document.getElementById("btn-google").removeChild(script);
    };
  }, []);

  return (
    <section className="bg-gradient-to-b from-[#B11217] flex items-center justify-center h-screen sm:h-[90vh]">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
            Une grande maison chaleureuse avec un personnel attentionné.
          </h1>
          <p className="max-w-2xl mb-6 font-medium lg:mb-8 md:text-lg lg:text-xl text-black">
            La résidence à été l'une des premières dans la région à recevoir la
            certification de conformité de l'Agence de la santé et services
            sociaux de Chaudière-Appalaches.
          </p>
          <link
            href="https://calendar.google.com/calendar/scheduling-button-script.css"
            rel="stylesheet"
          />
          <script
            src="https://calendar.google.com/calendar/scheduling-button-script.js"
            async
          ></script>
          <div id="btn-google"></div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img src={heroImage} alt="image hero" draggable="false" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
