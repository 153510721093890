import { useAuth } from "./AuthProvider";
import SignInSide from "./Login/login";
import { Navigate, Route, Routes } from "react-router-dom";
import Accueil from "./Accueil/accueil";
import Residence from "./Residence/residence";
import Profile from "./Dashboard/DashboardComponent/pages/dashboard/profile";
import Gallerie from "./Gallerie/Gallerie";
import Residents from "./Dashboard/DashboardComponent/pages/dashboard/Residents";
import ResidentAjout from "./Dashboard/DashboardComponent/pages/dashboard/ResidentsAjout";
import Employes from "./Dashboard/DashboardComponent/pages/dashboard/Employes";
import Familles from "./Dashboard/DashboardComponent/pages/dashboard/Familles";
import ProfileResident from "./Dashboard/DashboardComponent/pages/dashboard/ProfileResident";
import EmployeAjout from "./Dashboard/DashboardComponent/pages/dashboard/EmployesAjout";
import ProfileEmployes from "./Dashboard/DashboardComponent/pages/dashboard/ProfileEmploye";
import Postes from "./Dashboard/DashboardComponent/pages/dashboard/Postes";
import PosteAjout from "./Dashboard/DashboardComponent/pages/dashboard/PosteAjout";
import TypeChambre from "./Residence/TypeChambre";

export default function Routing(props) {
  const { user, loggedIn } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Accueil focusAnnonce={false}></Accueil>} />
      <Route
        path="/annonce"
        element={<Accueil focusAnnonce={true}></Accueil>}
      />
      <Route path="/residence" Component={Residence} />
      <Route path="/gallerie" element={<Gallerie />} />
      <Route path="/connexion" Component={SignInSide} />
      <Route path="/residence/:type" element={<Residence/>} />
      {loggedIn ? (
        <>
          <Route path="/dashboard/profil" element={<Profile/>} />
          <Route path="/dashboard/home" element={<Navigate to="/" />} />

          <Route
            path="/dashboard/employes"
            element={
              user.role === "Administrateur" ? (
                <Employes />
              ) : (
                <Navigate to="/dashboard/profil" />
              )
            }
          />
          <Route
            path="/dashboard/postes"
            element={
              user.role === "Administrateur" ? (
                <Postes />
              ) : (
                <Navigate to="/dashboard/profil" />
              )
            }
          />
          <Route
            path="/dashboard/employes/:id"
            element={<ProfileEmployes></ProfileEmployes>}
          />
          <Route
            path="/dashboard/residents/add"
            element={
              user.role === "Administrateur" ? (
                <ResidentAjout />
              ) : (
                <Navigate to="/dashboard/residents" />
              )
            }
          />
          <Route
            path="/dashboard/employes/add"
            element={
              user.role === "Administrateur" ? (
                <EmployeAjout />
              ) : (
                <Navigate to="/dashboard/profil" />
              )
            }
          />
          <Route
            path="/dashboard/postes/add"
            element={
              user.role === "Administrateur" ? (
                <PosteAjout />
              ) : (
                <Navigate to="/dashboard/profil" />
              )
            }
          />
          <Route
            path="/dashboard/familles"
            element={
              user.type === "Employé" ? (
                <Familles />
              ) : (
                <Navigate to="/dashboard/profil" />
              )
            }
          />
          <Route
            path="/dashboard/residents"
            element={
              user.type === "Employé" ? (
                <Residents />
              ) : (
                <Navigate to="/dashboard/profil" />
              )
            }
          />
          <Route path="/dashboard/residents/:id/edit" element={<ProfileResident enterInEditMode />} />
          <Route path="/dashboard/resident" element={<ProfileResident />} />
          <Route
            path="/dashboard/residents/:id"
            element={<ProfileResident />}
          />
          <Route
            path="/dashboard/*"
            element={<Navigate to="/dashboard/profil" />}
          />
        </>
      ) : null}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
