export default function TypeChambreChip({type, description, prix, onClick}) {
  return (
    <div className="mt-4 flex transition-shadow hover:shadow-md cursor-pointer border-2 border-gray-200 hover:border-gray-300 rounded-lg p-3" onClick={() => onClick()}>
      <div className="flex-1 text-[#B11217]">
        <span className="flex-1 text-3xl font-bold tracking-tight">
           {type}
        </span>
        <span>&emsp;${prix}*</span>
        <p className="text-black">{description}</p>
      </div>
    </div>
  );
}
