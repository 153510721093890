import { Avatar, Typography } from "@material-tailwind/react";
import { ProfileInfoCard } from "../../widgets/cards/index";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../../../Utility/Requester";
import { CircularProgress } from "@mui/material";

export default function ProfileEmploye() {
  const navigate = useNavigate();

  const { user, setAlert, setSeverity } = useAuth();
  const params = useParams();
  const [employe, setEmploye] = useState(null);

  const [loading, setLoading] = useState(true);

  const fetchEmploye = async () => {
    get(`/employes/get/${params.id}`)
      .then((data) => {
        setEmploye(data.employe);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setAlert(err);
      });
  };

  useEffect(() => {
    if (isNaN(params.id)) {
      navigate("/dashboard/employes");
    } else {
      fetchEmploye();
    }
  }, [setEmploye]);

  return (
    <Dashboard>
      {employe !== null ? (
        <div>
          <div className="mb-10 flex items-center justify-between flex-wrap gap-6">
            <div className="flex items-center gap-6">
              {loading && <CircularProgress className="text-[#B11217] absolute" size="6rem" color="inherit"/>}
              <Avatar
                src={axios.defaults.baseURL + "/employes/avatar/" + employe.id}
                onLoad={() => setLoading(false)}
                alt="employe"
                size="xxl"
                variant="rounded"
                className="rounded-lg shadow-lg shadow-blue-gray-500/40"
              />
              <div>
                <Typography variant="h5" color="blue-gray" className="mb-1">
                  {employe.prenom} {employe.nom}
                </Typography>
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  {employe.poste}
                </Typography>
              </div>
            </div>
          </div>
          <div className="grid gap-5 px-4 w-fit">
            <ProfileInfoCard
              title="Information du profil"
              details={{
                Email: employe.email,
                Genre: employe.genre != null ? employe.genre : "Aucun",
              }}
            />
          </div>
        </div>
      ) : <CircularProgress className="text-[#B11217] m-auto" color="inherit"/>}
    </Dashboard>
  );
}
