import { useState } from "react";
import defaultIcon from "../images/icons/test.jpg";
import { Box, Button, Fade, Popper } from "@mui/material";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const urlApi = process.env.REACT_APP_API_URL;

export default function ProfilePopper(props) {
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = urlApi;

  const { logout, avatarUrl } = useAuth();
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [arrowAnchor, setArrowAnchor] = useState(null);

  const handleProfileClick = (event) => {
    setAnchor(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleDisconnect = async (event) => {
    logout();
  };

  return (
    <div className={props.classes}>
      <svg
        viewBox="0 0 300 300"
        className="h-[50px] w-[50px] rounded-full cursor-pointer"
        onClick={props.shouldHandleClick ? handleProfileClick : null}
      >
        <clipPath id="pfp" className="rounded-full"></clipPath>
        <image
          href={avatarUrl}
          width={300}
          height={300}
          preserveAspectRatio="xMidYMid slice"
          clipPath="#pfp"
        />
        <circle
          cx={150}
          cy={150}
          r={150}
          fill="transparent"
          stroke="black"
          strokeWidth={8}
        ></circle>
      </svg>

      <Popper
        open={open}
        anchorEl={anchor}
        placement={"bottom"}
        transition
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: false,
            options: {
              element: arrowAnchor,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className="bg-white mt-1 border-2 border-gray-300 border-b-gray-500 border-r-gray-400 rounded-xl p-2 flex flex-col space-y-2">
              <NavLink to="/dashboard">
                <a
                  href="#"
                  draggable="false"
                  title=""
                  className="items-center justify-center inline-flex px-4 py-3 text-base font-semibold text-black transition-all duration-200 border-2 border-transparent rounded-md hover:text-[#B11217]"
                  role="button"
                >
                  {" "}
                  Panneau de bord{" "}
                </a>
              </NavLink>
              <a
                href="#"
                draggable="false"
                title=""
                className="items-center justify-center inline-flex px-4 py-3 text-base font-semibold text-white transition-all duration-200 bg-black border border-transparent rounded-md hover:bg-gray-900 focus:bg-gray-900"
                role="button"
                onClick={handleDisconnect}
              >
                {" "}
                Déconnexion{" "}
              </a>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
