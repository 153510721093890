import { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from '@mui/icons-material/Launch';

export default function Annonce() {
	var resizeTimeout;

	useEffect(() => {
		const handleResize = () => {
			clearTimeout(resizeTimeout);

			resizeTimeout = setTimeout(function () {
				var viewportWidth =
					window.innerWidth || document.documentElement.clientWidth;
				var iframe = document.getElementById("myIframe");

				if (viewportWidth < 500) {
					var newWidth = viewportWidth - 50 >= 180 ? viewportWidth - 100 : 180;
					iframe.src =
						"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbeaumont.residencecroiseebonheur%2F%3Flocale%3Dfr_CA&tabs=timeline&width=" +
						newWidth +
						"&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId";
					iframe.width = newWidth;
				} else if (viewportWidth >= 500 && iframe.width != 500) {
					var newWidth = 500;
					iframe.src =
						"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbeaumont.residencecroiseebonheur%2F%3Flocale%3Dfr_CA&tabs=timeline&width=" +
						newWidth +
						"&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId";
					iframe.width = newWidth;
				}
			}, 1000); // 3000 milliseconds = 3 seconds
		};

		// Add event listener when component mounts
		window.addEventListener("resize", handleResize);

		// Remove event listener when component unmounts or before navigating to another page
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []); // Empty dependency array ensures the effect runs only once when component mounts

	return (
		<section id="annonce" className="flex items-center justify-center h-min mb-28">
			<div className=" max-w-screen-xl px-4 py-2 mx-auto ">
				<div className="w-full place-self-center text-center lg:col-span-7">
					<h1 className="mb-4 m-auto text-4xl w-min inline-block font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-red-600">
						Annonces
					</h1>
					<InfoOutlinedIcon onClick={() => alert("Le widget est seulement visible sur ordinateur.")} titleAccess="Widget seulement visible sur ordinateur" fontSize="small"/>
				</div>
				<a className="m-auto block text-center text-lg hover:underline text-blue-600"  href="https://www.facebook.com/beaumont.residencecroiseebonheur">Résidence à la Croisée du Bonheur<span className="text-[0.7em]"><LaunchIcon fontSize="inherit" /></span></a>
				<div className="w-full">
					<iframe
						id="myIframe"
						src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbeaumont.residencecroiseebonheur%2F%3Flocale%3Dfr_CA&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
						width="500"
						height="500"
						style={{ border: "none", overflow: "hidden", maxWidth: "100vw" }}
						scrolling="no"
						frameBorder="0"
						allowFullScreen={true}
						allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
					></iframe>
				</div>
			</div>
		</section>
	);
}
