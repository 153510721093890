import 'react-phone-number-input/style.css'
import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import Dashboard from "../../../Dashboard";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import { Button } from "@material-tailwind/react";
import FormControl from '@mui/material/FormControl';
import Input from '@mui/joy/Input';
import PhoneInput from 'react-phone-number-input';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { get, post } from '../../../../Utility/Requester';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function ResidentAjout() {
    const { user, setAlert, setSeverity } = useAuth();

    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [dateNaissance, setDateNaissance] = useState(null);
    const [dateResidence, setDateResidence] = useState(null);
    const [telephone, setTelephone] = useState("");
    const [telephoneUrgence, setTelephoneUrgence] = useState("");
    const [chambre, setChambre] = useState("");
    const [chambres, setChambres] = useState([]);
    const [typeResidence, setTypeResidence] = useState('Permanent');

    const [loading, setLoading] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        get("/chambres/all")
            .then(function (data) {
                setChambres(data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setSeverity("error");
                setAlert(error);
            });
    }, []);

    const formatDate = (date) => {
        let dateObj = new Date(date);

        return dateObj.toISOString().slice(0, 10);
    }

    const sumbmitAjout = () => {
        if (dateResidence == null || prenom == null || prenom.trim() == "" || chambre == "" || typeResidence == "") {
            setSeverity("error");
            setAlert("Veuillez entrer tout les champs obligatoires");
        } else {
            let dateNaissanceFormatted = null;
            if (dateNaissance != null) {
                dateNaissanceFormatted = formatDate(dateNaissance);
            }

            let dateResidenceFormatted = formatDate(dateResidence);
            const trimmedTelephone = telephone != "" ? telephone.slice(-10) : null;
            const trimmedTelephoneUrgence = telephoneUrgence != "" ? telephoneUrgence.slice(-10) : null;

            post("/residents/add", {
                prenom: prenom,
                nom: nom,
                dateResidence: dateResidenceFormatted,
                dateNaissance: dateNaissanceFormatted,
                numeroChambre: chambre,
                typeResidence: typeResidence,
                telephone: trimmedTelephone,
                telephoneUrgence: trimmedTelephoneUrgence
            })
                .then(
                    data => {
                        setSeverity("success");
                        setAlert(data.message);
                        navigate("/dashboard/residents");
                    }
                )
                .catch((error) => {
                    console.log(error.message);
                    setSeverity("error");
                    setAlert(error.message);
                })
        }
    }

    const listItems = chambres.map((c) =>
        <MenuItem key={c.id} value={c.id} >{c.numero}</MenuItem>
    );

    return (
            user && (
                    <Dashboard>
                        <div className="w-full">
                            <div className="mb-10 flex items-center justify-between flex-wrap gap-5 px-4">
                                <h1 className='text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600'>Ajouter un résident</h1>
                            </div>

                            <div className="grid gap-5 px-4 ">
                                <FormControl>
                                    <div>Prénom <span className="text-red-600">*</span></div>
                                    <Input className="w-full xl:w-2/3 2xl:w-1/2" value={prenom} onChange={(newValue) => setPrenom(newValue.target.value)} />
                                    <br />

                                    <div>Nom <span className="text-red-600">*</span></div>
                                    <Input className="w-full xl:w-2/3 2xl:w-1/2" value={nom} onChange={(newValue) => setNom(newValue.target.value)} />
                                    <br />

                                    <div className='flex gap-3 mb-3 flex-col w-full sm:flex-row xl:w-2/3 2xl:w-1/2'>
                                        <div className='flex-grow '>
                                            <div>Date de naissance</div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                    <DatePicker
                                                        className='w-full bg-[#FBFCFE] border-[#CDD7E1]'
                                                        value={dateNaissance}
                                                        format='YYYY-MM-DD'
                                                        sx={{
                                                            '.MuiOutlinedInput-input': {
                                                                height: '11px'
                                                            }
                                                        }}
                                                        defaultValue={dayjs(new Date())}
                                                        onChange={setDateNaissance}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>

                                        <div className='flex-grow'>
                                            <div>Date d'arrivée à la résidence <span className="text-red-600">*</span></div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                    <DatePicker
                                                        className='w-full bg-[#FBFCFE] border-[#CDD7E1]'
                                                        value={dateResidence}
                                                        sx={{
                                                            '.MuiOutlinedInput-input': {
                                                                height: '11px'
                                                            }
                                                        }}
                                                        format='YYYY-MM-DD'
                                                        defaultValue={dateResidence}
                                                        onChange={setDateResidence}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className='flex gap-3 flex-col w-full sm:flex-row xl:w-2/3 2xl:w-1/2'>
                                        <div className='flex-grow '>
                                            <div>Numéro de téléphone </div>
                                            <PhoneInput
                                                className="rounded-md p-2 bg-[#FBFCFE] border-[#CDD7E1] border-[1px] h-11"
                                                countries={["CA"]}
                                                defaultCountry='CA'
                                                value={telephone}
                                                onChange={setTelephone}
                                            />
                                        </div>

                                        <div className='flex-grow'>
                                            <div>Numéro de téléphone d'urgence</div>
                                            <PhoneInput
                                                className="rounded-md p-2 bg-[#FBFCFE] border-[#CDD7E1] border-[1px] h-11"
                                                countries={["CA"]}
                                                defaultCountry='CA'
                                                value={telephoneUrgence}
                                                onChange={setTelephoneUrgence}
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-3 w-full md:w-1/2'>
                                        <div className='w-full sm:w-1/2 md:w-full xl:w-2/3 2xl:w-1/2'>
                                            <div>Numéro de chambre <span className="text-red-600">*</span></div>
                                            <Select
                                                className='w-full bg-[#FBFCFE] border-[#CDD7E1] h-11'
                                                value={chambre}
                                                onChange={(newValue) => setChambre(newValue.target.value)}
                                            >
                                                <MenuItem value=""></MenuItem>
                                                {listItems}
                                            </Select>
                                            {loading && <CircularProgress className="text-[#B11217] m-auto" color="inherit"/>}
                                        </div>
                                    </div>

                                    <div className='mt-3 w-full md:w-1/2'>
                                        <div className='w-full sm:w-1/2 md:w-full xl:w-2/3 2xl:w-1/2'>
                                            <div>Type de résidence <span className="text-red-600">*</span></div>
                                            <Select
                                                className='w-full bg-[#FBFCFE] border-[#CDD7E1] h-11'
                                                value={typeResidence}
                                                onChange={(newValue) => setTypeResidence(newValue.target.value)}
                                            >
                                                <MenuItem value="Permanent">Permanent</MenuItem>
                                                <MenuItem value="Convalescence">Convalescence</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </FormControl>

                                <legend><span className="text-red-600">*</span> Obligatoire</legend>
                                <Button className="bg-red-600 px-2 w-52 mt-5" onClick={() => sumbmitAjout()}>Soumettre</Button>
                            </div>
                        </div >
                    </Dashboard >

            )
    );

}