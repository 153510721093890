import React, {
	createContext,
	useState,
	useCallback,
	useEffect,
	useRef,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { get, post } from "./Utility/Requester";
import CustomAlert from "./Utility/CustomAlert";

const AuthContext = createContext();
const urlApi = process.env.REACT_APP_API_URL;

function AuthProvider({ children }) {
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = urlApi;

	const navigate = useNavigate();
	let baseValue = localStorage.getItem("previousLoggedIn") === "true";
	const previousLoggedIn = useRef(baseValue); // Ref to store previous loggedIn state

	function useOnChangeLogIn(initialValue, destination) {
		const [value, setValue] = useState(initialValue);

		const onChange = useCallback(
			(newValue) => {
				const oldValue = previousLoggedIn.current;
				setValue(newValue);
				localStorage.setItem("previousLoggedIn", JSON.stringify(newValue));
				if (newValue && newValue != oldValue) {
					navigate(destination);
				}
			},
			[navigate, destination]
		);

		return [value, onChange];
	}

	const [loggedIn, setLoggedIn] = useOnChangeLogIn(
		previousLoggedIn.current,
		"dashboard"
	);

	const [user, setUser] = useState(null);
	const [alert, setAlert] = useState("");
	const [severity, setSeverity] = useState("error");
	const [isLoading, setIsLoading] = useState(true);
	const [recaptchaRequested, setRecaptchaRequested] = useState(false);

	const clearAlert = () => {
		setAlert("");
	};

	useEffect(() => {
		// Check authentication status when component mounts
		const checkAuthentication = () => {
			get("/connected", true, 2).then(
					data => {
						const nouvelUser = {
							acc_id: data.acc_id,
							email: data.email,
							role: data.role,
							type: data.type,
							prenom: data.prenom,
							nom: data.nom,
							genre: data.genre,
							poste: data.poste,
						};
						setLoggedIn(true);
						setUser(nouvelUser);
					},
				).catch((err) => {
					setRecaptchaRequested(err.captcha);
					if (loggedIn === true) {
						setAlert("Vous avez été déconnecté.");
					}
					setLoggedIn(false);
				}).finally(() => {
					setIsLoading(false);
				});
		};

		checkAuthentication();
	}, [setLoggedIn, setAlert]);

	useEffect(() => {
		previousLoggedIn.current = loggedIn;
	}, [loggedIn]);

	const login = async (
		email,
		password,
		recaptcha,
		failedAttempt,
		incrementFailedAttempt
	) => {
		if (failedAttempt >= 3) {
			const captchaValue = recaptcha.current.getValue();
			if (!captchaValue) {
				setAlert("Veuillez cocher la case du reCAPTCHA!");
			} else {
				post("/verify", {
					captchaValue: captchaValue,
				},1).then(
					data => {
						console.log(data);
						formLogin(email, password, incrementFailedAttempt)
					}
				).catch((err) => {
					console.log(err);
					setAlert(err);
				});

			}
		} else {
			await formLogin(email, password, incrementFailedAttempt);
		}
	};

	const formLogin = async (email, password, incrementFailedAttempt) => {
	
			await post("/checkCredentials", {
				username: email,
				password: password,
			},1).then(
				data => {
					setLoggedIn(true);
				const nouvelUser = {
					email: data.email,
					role: data.role,
					type: data.type,
					prenom: data.prenom,
					nom: data.nom,
					genre: data.genre,
					poste: data.poste,
				};
				setUser(nouvelUser);
				setRecaptchaRequested(false);
				clearAlert();
				}
			).catch(err => {
				setLoggedIn(false);
				setAlert(err.message || err);
				if (!err.message) incrementFailedAttempt();
			})
	};

	const logout = async () => {
		// Perform logout logic here
		let data = await post("/logoff").catch(err => err);
		if (data.success) {
			navigate("/");
			setLoggedIn(false);
		}
		setAlert(data.message);
	};


	const avatarUrl = axios.defaults.baseURL + "/utilisateurs/avatar";

	if (isLoading) {
		return <></>
	} else {
		return (
			<AuthContext.Provider
				value={{ user, loggedIn, alert, avatarUrl, recaptchaRequested, setSeverity, setAlert, clearAlert, login, logout }}
			>
				{alert && <CustomAlert alert={alert} alertSeverity={severity} closeAlert={clearAlert}/>}
				{children}
			</AuthContext.Provider>
		);
	}

}

const useAuth = () => React.useContext(AuthContext);

export { useAuth, AuthProvider };
