import axios from "axios";

function post(url, data = {}, maxIteration = 3, iteration = 1) {
  return axios.post(url,data).then((res) => {
      if (res.data.success) {
        return Promise.resolve(res.data)
      } else {
        if (iteration >= maxIteration) 
          return Promise.reject(res.data);
        else
          return post(url, data, maxIteration, ++iteration);
      }
    }).catch((err) => {
      if (iteration >= maxIteration) 
        return Promise.reject(err);
      else {
        return post(url, data, maxIteration, ++iteration);
      }
    });
}

function del(url, data = {}, maxIteration = 3, iteration = 1) {
  return axios.delete(url,data).then((res) => {
      if (res.data.success) {
        return Promise.resolve(res.data)
      } else {
        if (iteration >= maxIteration || res.status === 401) 
          return Promise.reject(res.data);
        else
          return del(url, data, maxIteration, ++iteration);
      }
    }).catch((err) => {
      if (iteration >= maxIteration)
        return Promise.reject(err);
      else {
        return del(url, data, maxIteration, ++iteration);
      }
    });
}

function get(url, detailedFail = false, maxIteration = 3, data = {}, iteration = 1) {
    return axios.get(url, data).then((res) => {
        if (res.data.success !== undefined && res.data.success === false) {
          if (iteration >= maxIteration) {
            return Promise.reject(detailedFail == true ? res.data : res.data.message);
          } else {
            return get(url, detailedFail, maxIteration, data, iteration+1);
          }
        } else {
          return Promise.resolve(res.data);
        }
      }).catch((err) => {
        if (iteration >= maxIteration) {
          return Promise.reject(err);
        } else {
          return get(url, detailedFail, maxIteration, data, iteration+1);
        }
      })
  }

export { get, post, del };
