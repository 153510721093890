export default function useSorter() {
  /**
   *
   * @param {{}|null} a
   * @param {{}|null} b
   * @param {string} element
   * @returns
   */
  const compareJSON = (a, b, element) => {
    let val = 0;
    if (a[element] === null && b[element] === null) {
      val = 0;
    } else if (a[element] === null) {
      val = -1;
    } else if (b[element] === null) {
      val = 1;
    } else if (
      typeof a[element] === "bigint" ||
      typeof a[element] === "number"
    ) {
      val = a[element] - b[element];
    } else if (typeof a[element] === "boolean") {
      if (a[element] && b[element]) {
        val = 0;
      } else
      if (a[element]) {
        val = 1;
      } else
      if (b[element]) {
        val = -1;
      }
    } else if (typeof a[element] === "string") {
        val = a[element].localeCompare(b[element], {}, {caseFirst:"upper",usage:"sort"});
    }
    else if (
      typeof a[element] === "object"
    ) {
      if (
        a[element].toString().toLowerCase() <
        b[element].toString().toLowerCase()
      ) {
        val = -1;
      } else if (
        a[element].toString().toLowerCase() >
        b[element].toString().toLowerCase()
      ) {
        val = 1;
      } else {
        val = 0;
      }      
    }
    return val;
  };

  /**
   *
   * @param {[{}]} array
   * @param {string} element
   */
  const sortAscending = (array, element) => {
    return array.sort((a, b) => compareJSON(a, b, element));
  };

  /**
   *
   * @param {[{}]} array
   * @param {string} element
   */
  const sortDescending = (array, element) => {
    return array.sort((a, b) => -compareJSON(a, b, element));
  };

  return {
    sortAscending,
    sortDescending,
  };
}
