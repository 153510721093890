import { CircularProgress, Typography, Zoom } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, ArrowBack } from '@mui/icons-material';
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "../Utility/Requester";
import { useAuth } from "../AuthProvider";

export default function TypeChambre({ type }) {
  const { setAlert, setSeverity } = useAuth();
  const [typeChambre, setTypeChambre] = useState();
  const [chambres, setChambres] = useState([]);
  const [plans, setPlans] = useState([]);
  const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const getChambres = () => {
    get(`/chambres/fromType/${typeChambre.type}`).then(
      data => {
        setChambres(data);
      },
    ).catch(err => {
      setSeverity("error");
      setAlert(err);
    })
  };

  const switchPlan = () => {
    let container = document.getElementById("plans")
    container.scrollTo({
      top:0,
      left: container.scrollLeft == 0 ? container.scrollWidth : 0,
      behavior: "smooth",
    });
  }

  const zoomPlan = (plan) => {
    let parent = plan.parentNode;
    if (parent.classList.contains("fixed")) {
      parent.classList.remove("fixed");
      plan.classList.remove("cursor-zoom-out");
    } else {
      parent.classList.add("fixed");
      plan.classList.add("cursor-zoom-out");
    }
  }

  useEffect(() => {
    get(`/chambres/types/${type}`).then(
      data => {
        setTypeChambre(data);
      }
    ).catch(err => {
      setSeverity("error");
      setAlert(err);
    });
  }, []);

  useEffect(() => {
    if (typeChambre) {
        const importAll = (r) => r.keys().map(r);
        const plansImages = importAll(
          require.context("../../images/plans", false, /\.(png|jpe?g|svg)$/)
        );
        const imagesChambres = importAll(
            require.context(`../../images/chambres`, false, /\.(png|jpe?g|svg)$/)
        );
        setImages(imagesChambres);
        setPlans(plansImages);
        getChambres();
    }
  },[typeChambre]);

  
  return (
    typeChambre ? (
      <div className="min-h-max w-10/12 mt-10 bg-white p-10 rounded-t-xl">
        <div className="mb-3 flex items-center text-[#B11217] font-bold hover:underline cursor-pointer" onClick={() => navigate("/residence")}><ArrowBack fontSize="medium"/><h1 className="ml-1 text-xl inline">Types de chambre</h1></div>
        <h1 className=" mb-10 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-[#B11217] text-center">
          {typeChambre.type}
        </h1>
        <div className="flex flex-col lg:flex-row gap-x-5">
          <ul className="flex flex-col gap-y-3 w-full lg:w-1/2">
            <li className="flex-wrap">
              <Typography
                variant="h6"
                fontWeight="bold"
                color="blue-gray"
              >
                Description :
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="normal"
              >{typeChambre.description_base}</Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="blue-gray"
              >
                Prix de base :
              </Typography>
              <Typography
              variant="subtitle1"
                fontWeight="normal"
              >{typeChambre.prix_base}$</Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="blue-gray"
              >
                Chambres disponibles :
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="normal"
              >
              {chambres.length === 0 ? "Aucune chambre n'est actuellement disponible" : chambres
                  .filter((c) => c.disponible)
                  .map((c) => c.numero)
                  .join(", ")}
              </Typography>
            </li>
          </ul>
          <div className="lg:w-1/2 text-center">
          <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold"
              >
                Plans
              </Typography>
            <div className="flex items-center">
              <ArrowBackIos onClick={() => switchPlan()}/>
            <div id="plans" className="flex snap-x snap-mandatory overflow-scroll">
              {plans.map((p) => (
                <div className="snap-center shrink-0 w-full">
                  <div className="left-0 top-0 right-0 bottom-0 max-h-screen bg-black/50 flex items-center">
                    <img className="left-0 top-0 right-0 m-auto cursor-zoom-in w-auto max-h-screen" width={400} height={300} onClick={(e) => zoomPlan(e.target)} src={p} alt="plan" />
                  </div>
                </div>
              ))}
            </div>
            <ArrowForwardIos onClick={() => switchPlan()}/>
            </div>
          </div>
        </div>
        {images.length > 0 && <div>
        <h1 className=" my-5 text-lg font-extrabold tracking-tight leading-none md:text-2xl lg:text-3xl text-[#B11217] text-center">
          Galerie
        </h1>
        <div className="flex snap-x snap-mandatory overflow-scroll border-t-2 p-5">
              {images.map((i) => (
                <div className="snap-center shrink-0 w-full">
                  <img src={i} width="400" className="m-auto" alt="plan" />
                </div>
              ))}
              </div>
              </div>
}
      </div>
    ) : <CircularProgress className="text-[#B11217] mt-4 m-auto" color="inherit"/>
  );
}
