import "react-phone-number-input/style.css";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import Dashboard from "../../../Dashboard";
import { Button } from "@material-tailwind/react";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/joy/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../../../Utility/Requester";

export default function PosteAjout() {
  const { user, setAlert, setSeverity } = useAuth();

  const [nomMasculin, setNomMasculin] = useState("");
  const [nomFeminin, setNomFeminin] = useState("");
  const [role, setRole] = useState("");

  const [postes, setPostes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    get("/postes/role/get")
      .then(function (data) {
        setPostes(data);
        setRole("Base");
      })
      .catch(function (error) {
        console.log(error);
        setSeverity("error");
        setAlert(error);
      });
  }, []);

  let i = 0;
  const listItems = postes.map((c) => (
    <MenuItem key={++i} value={c}>
      {c}
    </MenuItem>
  ));

  const sumbmitAjout = () => {
    if (
      nomMasculin == null ||
      nomMasculin.trim() == "" ||
      nomFeminin == null ||
      nomFeminin.trim() == "" ||
      role == null ||
      role.trim() == ""
    ) {
      setSeverity("error");
      setAlert("Veuillez entrer tout les champs obligatoires");
    } else {
      post("/postes/add", {
        nomMasculin: nomMasculin,
        nomFeminin: nomFeminin,
        role: role,
      })
        .then((data) => {
          setSeverity("success");
          setAlert(data.message);
          navigate("/dashboard/postes");
        })
        .catch(function (error) {
          console.log(error);
          setSeverity("error");
          setAlert(error.message);
        });
    }
  };

  return (
    <Dashboard>
      <div className="w-full">
        <div className="mb-10 flex items-center justify-between flex-wrap gap-5 px-4">
          <h1 className="text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
            Ajouter un poste
          </h1>
        </div>

        <div className="grid gap-5 px-4 ">
          <FormControl>
            <div>
              Nom masculin <span className="text-red-600">*</span>
            </div>
            <Input
              className="w-full xl:w-2/3 2xl:w-1/2"
              value={nomMasculin}
              onChange={(newValue) => setNomMasculin(newValue.target.value)}
            />
            <br />

            <div>
              Nom féminin <span className="text-red-600">*</span>
            </div>
            <Input
              className="w-full xl:w-2/3 2xl:w-1/2"
              value={nomFeminin}
              onChange={(newValue) => setNomFeminin(newValue.target.value)}
            />
            <br />

            <div className="mt-3 w-full md:w-1/2">
              <div className="w-full sm:w-1/2 md:w-full xl:w-2/3 2xl:w-1/2">
                <div>Rôle</div>
                <Select
                  className="w-full bg-[#FBFCFE] border-[#CDD7E1] h-11"
                  value={role}
                  onChange={(newValue) => setRole(newValue.target.value)}
                >
                  {listItems}
                </Select>
              </div>
            </div>
          </FormControl>

          <legend>
            <span className="text-red-600">*</span> Obligatoire
          </legend>
          <Button
            className="bg-red-600 px-2 w-52 mt-5"
            onClick={() => sumbmitAjout()}
          >
            Soumettre
          </Button>
        </div>
      </div>
    </Dashboard>
  );
}
