/**
=========================================================
* Material Tailwind Dashboard React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-tailwind-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-tailwind-dashboard-react/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import App from "./App";
import { MaterialTailwindControllerProvider } from "./context/index";

export default function DashboardMain({ children }) {
	return (
		<MaterialTailwindControllerProvider>
			<App>{children}</App>
		</MaterialTailwindControllerProvider>
	);
}
