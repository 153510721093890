import Header from "../header";
import Hero from "./hero";
import Annonce from "./annonce";
import SellingPoint from "./SellingPoint";
import Footer from "../footer";
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';


export default function Accueil(props) {
	const location = useLocation();

	useEffect(() => {
		if (props.focusAnnonce == true) {
			const section = "annonce";

			const scrollToSection = () => {
				const sectionElement = document.getElementById(section);
				if (sectionElement) {
					sectionElement.scrollIntoView({ behavior: "smooth" });
				}
			};

			if (section != null && section != undefined) {
				scrollToSection();
			}
		}
	}, [props.focusAnnonce]);

	return (
		<>
			<Header></Header>
			<Hero></Hero>
			<SellingPoint></SellingPoint>
			<Annonce></Annonce>
			<Footer></Footer>
		</>
	);
}
