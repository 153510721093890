import SendIcon from "@mui/icons-material/Send";
import { Button, Fade, FormControl, Slide, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import { post } from "../../../../Utility/Requester";

export default function CommentaireAjout({ residentId, showing, close }) {
  const { setAlert, setSeverity } = useAuth();

  const [texte, setTexte] = useState("");
  const [done, setDone] = useState(false);

  //TODO vérifier pour failles XSS
  const onSend = async () => {
    if (texte.trim() === "") {
      setAlert(
        "Le commentaire ne peut pas être vide. Veulliez entrez du texte."
      );
      setSeverity("error");
    } else {
      post("/commentaireResidents/add", {
        commentaire: texte,
        residentId: residentId,
      })
        .then((data) => {
          setDone(true);
          setSeverity("success");
          setAlert(data.message);
        })
        .catch((error) => {
          setSeverity("error");
          setAlert(error.message);
          console.log("Erreur lors de l'envoie d'un commentaire : " + error);
        });
    }
  };

  return (
    <Fade
      in={showing}
      className="fixed z-[199] top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50"
    >
      <div>
        <Slide
          direction="down"
          in={showing && !done}
          mountOnEnter
          unmountOnExit
          onExited={() => close()}
        >
          <div className="fixed top-1/4 left-4 md:left-1/4 z-[200] min-w-[90%] md:min-w-min min-h-80 md:w-1/2 h-fit bg-[#B11217] rounded-lg">
            <FormControl
              required
              className="w-full h-full min-h-80 flex flex-col"
            >
              <div className="pt-4 pl-4">
                <Typography color={"white"} variant="h6">
                  Entrez votre commentaire :
                </Typography>
              </div>
              <div className="grow px-4 flex flex-col">
                <textarea
                  required
                  className="w-full h-full grow rounded-lg p-2 bg-white border-2 border-black"
                  variant="outlined"
                  onChange={(e) => setTexte(e.target.value)}
                />
              </div>

              <div className="flex justify-around my-2">
                <Button color="inherit" variant="contained" onClick={close}>
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  disabled={texte.trim().length === 0}
                  color="success"
                  endIcon={<SendIcon />}
                  onClick={() => onSend()}
                >
                  Envoyer
                </Button>
              </div>
            </FormControl>
          </div>
        </Slide>
      </div>
    </Fade>
  );
}
