import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";


import { AuthProvider } from "./components/AuthProvider";
import Routing from "./components/Routing";

const urlApi = process.env.REACT_APP_API_URL;

function App() {
	const getCSRFToken = async () => {
		try {
			const response = await axios.get("/getCSRFToken");
			axios.defaults.withXSRFToken = true;
			axios.defaults.xsrfCookieName = "x-csrf-token";
			axios.defaults.headers["x-csrf-token"] = response.data.csrfToken;
			axios.defaults.headers.post["x-csrf-token"] = response.data.csrfToken;
			axios.defaults.headers.delete["x-csrf-token"] = response.data.csrfToken;
		} catch (err) {
			getCSRFToken();
		}
	};

	

	useEffect(() => {
		getCSRFToken();
	},[]);

	return (
		<BrowserRouter>
			<AuthProvider>
				<Routing></Routing>
			</AuthProvider>
		</BrowserRouter>
	);
}

export { App, urlApi };
