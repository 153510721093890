import React from "react";
import { useAuth } from "../AuthProvider";
import { Card, CircularProgress } from '@mui/material';
import DashboardMain from "./DashboardComponent/DashboardMain";
import { CardBody } from "@material-tailwind/react";
import { Navigate } from "react-router-dom";


function Dashboard({ children }) {
	const { user } = useAuth();

	return (
		<DashboardMain>
			<Card className="mx-3 mt-6 lg:mx-4 borderborder-blue-gray-100 min-h-[79vh] h-full">
				<CardBody className="grid grid-cols-1 p-4 h-full">
					{user ? children : <CircularProgress className="text-[#B11217] m-auto" color="inherit"/>}
				</CardBody>
			</Card>
		</DashboardMain>

	);
}

export default Dashboard;
