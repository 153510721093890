import Header from "../header";
import Footer from "../footer";
import Grid from "./Grid";
import axios from "axios";
import Equipe from "./Equipe/Equipe";
import Videos from "./Videos";

const urlApi = process.env.REACT_APP_API_URL;

export default function Gallerie() {
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = urlApi;

  return (
    <div className="grid h-full">
      <Header />
      <Equipe />
      <Grid />
      <Videos/>
      <Footer />
    </div>
  );
}
