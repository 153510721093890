import { Alert, AlertTitle, Slide, ThemeProvider, createTheme, useTheme } from "@mui/material";
import Close from "../../images/icons/Close";
import { useMemo, useEffect, useState } from "react";
import { frFR } from "@mui/material/locale";

export default function CustomAlert({ alertSeverity = "success", closeAlert, alert }) {
  const [opened, setOpened] = useState(true);

  const theme = useTheme();
  const themeLocale = useMemo(() => createTheme(theme, frFR),[theme,frFR])

  const handleClose = () => {
    if (!opened) {
      closeAlert();
    }
  };

  return (
    <ThemeProvider theme={themeLocale}>
    <Slide in={opened} mountOnEnter unmountOnExit onExited={handleClose}>
      <Alert
        className={"transition-all fixed z-[100] top-1 left-1 right-1 md:top-3 md:left-3 md:right-3 border-[1px] " +
          (alertSeverity == "error"
            ? "border-red-600"
            : "border-green-600")
        }
        severity={alertSeverity}
        action={
          <span
            className="float-right cursor-pointer"
            onClick={() => setOpened(false)}
          >
            <Close
              width="25px"
              height="25px"
              fill={alertSeverity == "error" ? "red" : "green"}
            ></Close>
          </span>
        }
      >
        <AlertTitle className="w-full">
          {alertSeverity === "success" ? "Succès" : "Erreur"}
        </AlertTitle>
        <p className="w-full whitespace-pre-wrap">{alert}</p>
      </Alert>
    </Slide>
    </ThemeProvider>
  );
}
