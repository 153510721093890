import { useState } from "react";
import defaultIcon from "../../../images/icons/test.jpg";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export default function Employe(props) {
	const [loaded, setLoaded] = useState(false);
	const [src, setSrc] = useState(axios.defaults.baseURL + "/employes/avatar/" + props.id);

	return (
			<div className="flex flex-col w-[120px] sm:w-[220px] lg:w-[320px] items-center justify-center h-fit">
				{!loaded && <CircularProgress className="text-[#B11217] mb-[12%] md:mb-[6%] lg:mb-[4%] absolute m-auto" color="inherit"/>}
				<svg
					viewBox="0 0 300 300"
					className="h-[100px] w-[100px] sm:h-[200px] sm:w-[200px] lg:w-[300px] lg:h-[300px] rounded-full"
				>
					<clipPath id="pfp" className="rounded-full"></clipPath>
					<image
						href={src}
						onLoad={() => setLoaded(true)}
						onError={(e) => setSrc(null)}
						width={300}
						height={300}
						preserveAspectRatio="xMidYMid slice"
						clipPath="#pfp"
					/>
					<path
						id="textCircle"
						stroke="black"
						strokeWidth={35}
						fill="none"
						d="M-5,250a200,168 0 1,1 500,-100"
						className="invisible sm:visible"
					/>
					<text
						fill="white"
						className="uppercase hidden sm:inline"
						dy="12"
						dx={230 - (props.poste != null ? props.poste.length * 5 : 0)}
					>
						<textPath className="text-base" xlinkHref="#textCircle">
							{props.poste}
						</textPath>
					</text>
					<circle
						cx={150}
						cy={150}
						r={150}
						fill="transparent"
						stroke="black"
						strokeWidth={8}
					></circle>
				</svg>

				<p className="text-lg text-center sm:text-2xl font-medium sm:font-normal mt-2">
					{props.prenom} {props.nom}
				</p>
				<p className="sm:hidden">{props.poste}</p>
			</div>
	);
}
