//width={props.width} height={props.height} fill={props.fill}
export default function Fireplace(props) {
    return (
        <svg width={props.width} height={props.height} fill={props.fill} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 406.525 406.525">
            <g>
                <path d="M89.876,170.95l17.802,173.179c0,4.641,3.772,8.405,8.405,8.405h180.243
		c4.666,0,8.437-3.764,8.437-8.405l5.137-49.958c4.568,1.065,9.283,1.674,14.127,1.674c34.579,0,62.606-28.027,62.606-62.606
		c0-34.563-28.027-62.59-62.606-62.59c-0.496,0-0.984,0.098-1.488,0.098c-0.122-4.552-3.837-8.169-8.381-8.169H98.281
		C93.664,162.569,89.876,166.308,89.876,170.95z M320.215,193.686c1.276-0.122,2.544-0.187,3.82-0.187
		c21.972,0,39.765,17.785,39.765,39.741c0,21.972-17.785,39.789-39.765,39.789c-4.097,0-8.055-0.618-11.778-1.772L320.215,193.686z"
                />
                <path d="M1.014,369.734c-2.577,2.585,0.187,10.754,3.471,16.688c3.268,5.926,5.82,14.103,8.405,16.688
		c1.528,1.52,3.633,2.455,5.958,2.455h368.85c2.317,0,4.422-0.943,5.942-2.471c2.577-2.569,5.121-10.738,8.397-16.672
		c3.276-5.926,6.056-14.095,3.471-16.688c-1.528-1.528-3.633-2.479-5.958-2.479H6.981C4.64,367.263,2.543,368.214,1.014,369.734z"/>
                <path d="M173.649,77.227c-6.6,4.471-26.686,18.078-22.89,35.4c4.446,20.281,38.944,26.971,45.813,28.109
		c0.455,0.081,0.894,0.114,1.333,0.114c3.902,0,7.348-2.829,8.007-6.804c0.74-4.43-2.26-8.616-6.69-9.34
		c-12.372-2.048-31.092-8.763-32.579-15.55c-0.699-3.178,3.178-9.689,16.127-18.46c17.038-11.551,24.402-20.565,24.638-30.149
		c0.276-10.982-8.698-17.745-18.2-24.906c-9.933-7.486-20.2-15.225-22.817-28.166c-0.894-4.398-5.129-7.234-9.584-6.357
		c-4.398,0.886-7.251,5.186-6.357,9.584c3.845,18.988,18.371,29.929,28.962,37.92c5.267,3.959,11.803,8.901,11.738,11.518
		C191.142,61.019,190.199,66.01,173.649,77.227z"/>
                <path d="M191.288,100.971c-0.797,4.422,2.146,8.641,6.568,9.437c2.65,0.48,5.739,0.772,9.063,0.772
		c17.33,0,41.415-7.901,47.105-38.798c0.821-4.422-2.105-8.657-6.519-9.47c-4.471-0.862-8.649,2.113-9.47,6.519
		c-5.446,29.523-34.099,25.54-37.343,24.963C196.255,93.647,192.085,96.573,191.288,100.971z"/>
            </g>
        </svg>);
}
