export default function useResidentEditor(resident) {
  const formatDate = (date) => {
    let dateObj = new Date(date);

    return dateObj.toISOString().slice(0, 10);
  };

  const prenom = { val: resident && resident.prenom };
  const nom = { val: resident && resident.nom };
  const typeResidence = { val: resident && resident.type_residence };
  const dateResidence = {
    val:
      resident &&
      resident.date_residence &&
      formatDate(resident.date_residence),
  };
  const dateNaissance = {
    val:
      resident &&
      resident.date_naissance &&
      formatDate(resident.date_naissance),
  };
  const telephone = { val: resident && resident.telephone };
  const telephoneUrgence = { val: resident && resident.telephone_urgence };
  const chambre = { val: resident && resident.numero };

  const setPrenom = (value) => {
    prenom.val = value;
  };

  const setNom = (value) => {
    nom.val = value.val;
  };

  const setTypeResidence = (type) => {
    typeResidence.val = type;
  };

  const setChambre = (value) => {
    chambre.val = value;
  };

  const setDateNaissance = (value) => {
    dateNaissance.val = value;
  };

  const setDateResidence = (value) => {
    dateResidence.val = value;
  };

  const setTelephone = (value) => {
    telephone.val = value;
  };

  const setTelephoneUrgence = (value) => {
    telephoneUrgence.val = value;
  };

  const getResidentEdit = () => {
    return {
      prenom: prenom.val,
      nom: nom.val,
      chambre: chambre.val,
      dateNaissance: dateNaissance.val,
      dateResidence: dateResidence.val,
      telephone: telephone.val,
      telephoneUrgence: telephoneUrgence.val,
      typeResidence: typeResidence.val,
    };
  };

  return {
    setPrenom,
    setNom,
    setTypeResidence,
    setChambre,
    setDateNaissance,
    setDateResidence,
    setTelephone,
    setTelephoneUrgence,
    getResidentEdit,
  };
}
