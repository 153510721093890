import React, { useEffect, useState } from "react";

export default function Grid() {
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(
      require.context("../../images/galerie", false, /\.(png|jpe?g|svg)$/)
    );
    setPictures(images);
  }, []);

  return (
    <div>
      <h2 className="w-full mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-[#B11217] text-center flex justify-center items-center">
        Galerie
      </h2>
      <div className="min-h-max w-9/12  p-5 pb-10 mx-auto mb-10 gap-5 space-y-5 sm:columns-2  md:columns-3">
        {pictures.map((picture, index) => (
          <img
            key={index}
            src={picture}
            alt={`Image ${index}`}
            draggable="false"
          />
        ))}
      </div>
    </div>
  );
}
