import * as React from "react";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import { useEffect, useState } from "react";
import DefaultSortableTable from "./DefaultSortableTable";
import { get } from "../../../../Utility/Requester";
import { CircularProgress } from "@mui/material";

export default function Familles() {
	const { user, setAlert, setSeverity } = useAuth();
	const [rows, setRows] = useState([]);

	const [loading, setLoading] = useState(true);

	const headCells = [
		{
			id: "email",
			label: "Email",
		},
		{
			id: "resident",
			label: "Résident",
		},
	];

	useEffect(() => {
		get("/familles/all")
			.then(function (data) {
				setRows(data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setSeverity("error");
				setAlert(error);
			});
	}, []);

	return (
		<Dashboard>
			<div className="w-full mb-3 ">
				<h1 className="flex-none float-left text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
					Familles
				</h1>
			</div>
			<DefaultSortableTable 
				rows={rows} 
				headCells={headCells} 
				onRowNavigation={"/dashboard/residents/"} 
				rowNavigationParam="resident_id" 
				deleteable = {false}
				rowKeyParam={"id"}
			/>
			{loading && <CircularProgress className="text-[#B11217] mt-4 m-auto" color="inherit"/>}
		</Dashboard>

	);
}
